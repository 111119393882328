var httpUtils = require('./http-utils.js')
function createRoom(name,position,cb){
    httpUtils._post('/rooms', { name:name,position:position,
        chatroom: [
            {
              username: "System",
              message:
                "Création du salon le " +
                new Date().toLocaleDateString("fr-FR") +
                " à " +
                new Date().toLocaleTimeString("fr-FR"),
            },
          ] }, cb)
}

function getUsers(roomId, cb) {
  httpUtils._get('/rooms/'+roomId+'/users', cb);
}

function getRooms(cb) {
  httpUtils._get('/rooms?filter[include]=users', cb);
}

function getAutoRoom(userId, cb) {
  httpUtils._get("/users/" + userId + "/getAutoRoom", cb);
}

module.exports = { createRoom, getUsers, getAutoRoom, getRooms }