<template>
  <div class="profil">
       <WvrzIconButton @click="backMain" class="logout" text="arrow_back" />
    <div class="parameters">
      <wvrz-input
        placeholderText="Nom du salon"
        class="name"
        v-model="name"
        :value="name"
        typeInput="text"
        ref="newMessageInput"
      ></wvrz-input>
      <cibi-create-room-map
        class="map"
        :markers="markers"
        :route="route"
      ></cibi-create-room-map>
      <div class="bottom">
        <div class="errMessage" ref="err">{{ errMessage }}</div>
        <div class="createRoomButton" @click="createRoom">Créer le salon</div>
      </div>
    </div>
  </div>
</template>

<script>
import WvrzIconButton from "../generics/WvrzIconButton.vue";
import CibiCreateRoomMap from "../others/CibiCreateRoomMap.vue";
import WvrzInput from "../generics/WvrzInput.vue";
export default {
  name: "CibiCreate",
  components: { WvrzIconButton, WvrzInput, CibiCreateRoomMap },
  data() {
    return {
      user: undefined,
      markers: [],
      name: "",
      errMessage: "",
    };
  },
  props: {
    route : String
  },
  methods: {
    backMain() {
      this.$router.push("/main/room");
    },
    createRoom() {
      if (this.name && this.name !== "" && this.markers.length >= 3) {
        this.errMessage = "";
        this.$.refs.err.style.background = "initial";
        console.log("creating room", this.name, this.markers);
        let position = [];
        this.markers.forEach((element) => {
          position.push({ lat: element.pos[1], lng: element.pos[0] });
        });
        this.$room.createRoom(this.name, position, (err, value) => {
          if (err) {
            this.errMessage = "Erreur à la création du salon.";
            this.$.refs.err.style.background = "#adb0b8";
          } else {
            this.$router.push("/main/room");
            this.$emit("setNotification", {error: false, title: "Création de salon réussie", message: "Votre salon a bien été créé."})
          }
        });
      } else {
        if (this.name === "") {
          this.errMessage = "Merci de rentrer un nom pour le salon.";
        } else {
          if (this.markers.length <= 3) {
            this.errMessage = "Ajoutez 3 points minimum pour la zone.";
          } else {
            this.errMessage = "Erreur à la création du salon.";
          }
        }

        this.$.refs.err.style.background = "#adb0b8";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.bottom {
  display: flex;
}
.errMessage {
  font-family: $font-family;
  color: #d32f2f;
  font-weight: bold;
  font-size: 10pt;
  text-align: right;
  line-height: 42px;
  padding-right: 10px;
  margin-left: 20px;
  vertical-align: center;
  flex: 1;
}
.createRoomButton {
  align-self: end;
  margin-right: 20px;
  border-radius: 3px;
  font-family: $font-family;
  cursor: pointer;
  font-size: 14pt;
  padding: 10px;
  background-color: $button-background;
  color: $button-color;
}
.map {
  display: flex;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  flex: 1;
}
.name {
  margin-bottom: 20px;
}
.profil {
  height: 100%;
  font-family: $font-family;
  font-size: large;
  max-width: 800px;
  width: 60%;
  flex-direction: column;
  margin: 0 auto 0 auto;
  border-radius: 10px;
  transition: box-shadow 0.5s;
}

@media (max-width: 800px) {
  .profil {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .profil {
    width: 100%;
    padding-top: 0;
    margin: 0;
    height: 100%;
    border-radius: 0;
  }
}
.param-header {
  background-color: $create-room-header-background;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#arrow-back {
  width: fit-content;
  color: $text;
  padding: 2px;
  font-size: x-large;
  margin: 0px;
}
#arrow-back:hover {
  background-color: $text;
  cursor: pointer;
}

.title {
  color: $create-room-header-text;
  font-size: large;
  margin-right: auto;
  margin-left: auto;
  padding-right: 32px;
}
.parameters {
  background-color: $create-room-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.param {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: medium;
  color: $text;
  flex: 1;
}

.material-icons {
  color: $create-room-header-text;
  margin: 10px;
}
hr {
  margin: 0;
  border-color: $header-background;
}
hr:last-child {
  display: none;
}
.logout {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
