<template>
  <div class="home">
    <div id="header">
      <cibi-channels-list
        ref="channels"
        :channels="rooms"
        :selectedChannel="selected"
        @channelSelected="selectChannel"
        :rooms="rooms"
      ></cibi-channels-list>
    </div>
    <wvrz-slider
      class="pageContent"
      :page="this.currentPage"
      :initialPage="this.initialPage"
    >
      <cibi-participants-list
        ref="participantsList"
        id="participantsList"
        :elements="participants"
      ></cibi-participants-list>
      <cibi-chatroom
        id="chatRoom"
        :roomId="selected"
        @participantsChanged="participantsChanged"
      ></cibi-chatroom>
      <wvrz-leaflet-map
        ref="map"
        v-bind:class="{ mapOpened: isMapOpen }"
        :room="selectedRoom"
        :participants="participants"
        :page="this.currentPage"
        :route="route"
      ></wvrz-leaflet-map>
    </wvrz-slider>

    <WvrzTabBar
      class="tabBar"
      :tabs="navigationTabs"
      @switch="switchTab"
    ></WvrzTabBar>
    <div class="participant">
      <span class="material-icons participantIcon">account_circle</span>
      <span class="participantText"> {{ user.username }} </span>
      <wvrz-input
        placeholderText="Message"
        v-model="newMessage"
        :value="newMessage"
        typeInput="text"
        ref="newMessageInput"
        v-on:keyup.enter="onNewMessage"
      ></wvrz-input>
      <WvrzIconButton @click="mute" :text="getMicrophoneIcon(audioenabled)">
      </WvrzIconButton>
    </div>
  </div>
</template>

<script>
import CibiChatroom from "../others/CibiChatroom.vue";
import CibiChannelsList from "../others/CibiChannelsList.vue";
import CibiParticipantsList from "../others/CibiParticipantsList.vue";

import WvrzIconButton from "../generics/WvrzIconButton.vue";
import WvrzTabBar from "../generics/WvrzTabBar.vue";
import WvrzInput from "../generics/WvrzInput.vue";
import WvrzLeafletMap from "../generics/WvrzLeafletMap.vue";
import WvrzSlider from "../generics/WvrzSlider.vue";

export default {
  components: {
    CibiChatroom,
    CibiChannelsList,
    CibiParticipantsList,
    WvrzIconButton,
    WvrzLeafletMap,
    WvrzSlider,
    WvrzTabBar,
    WvrzInput,
  },
  name: "Main",
  props: {
    route : String,
    rooms: Array,
    selected: String,
    janusStatus: Object,
    position: Object,
    user: Object,
  },
  data() {
    return {
      participants: [],
      insideRoute: "",
      started: false,
      currentPage: 1,
      initialPage: 1,
      newMessage: "",
      audioenabled: true,
      isMapOpen: false,
      watchPositionId: null,
      navigationTabs: ["Participants", "Chat", "Carte"],
    };
  },
  computed: {
    selectedRoom: function () {
      var ret = null;
      if (!this.rooms) {
        return ret;
      }
      this.rooms.forEach((room) => {
        if (room.id === this.selected) {
          ret = room;
        }
      });
      return ret;
    },
  },
  methods: {
    participantsChanged(participants) {
      this.participants = participants;
    },
    windowSizeChange(e) {
      var w = window.innerWidth;
      if (w > 800) {
        this.currentPage = 0;
      } else {
        this.currentPage = 1;
      }
    },
    switchTab(tab) {
      this.currentPage = tab;
    },
    getMicrophoneIcon(value) {
      return value ? "mic" : "mic_off";
    },
    onNewMessage() {
      this.$chat.sendMessage(
        this.roomId,
        this.user.username,
        this.newMessage,
        () => {
          this.newMessage = "";
        }
      );
    },
    mute() {
      this.audioenabled = !this.audioenabled;
      this.$emit("mute",this.audioenabled);
    },
    selectChannel(channel) {
      this.$emit("channelSelected", channel);
    },
  },

  created() {
    window.addEventListener("resize", this.windowSizeChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChange);
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style.scss";
.geolocationOverlay {
  display: none;
}

.participant {
  width: 100%;
  display: flex;
  background: $participant-background;
  align-items: center;
  color: #7e8186;
  padding: 15px;
}
.participantIcon {
  font-size: 30pt;
  color: $icon-button-color;
  margin-right: 15px;
}
.participantText {
  font-size: 14pt;
  color: $text-color;
  font-family: Roboto;
  margin-right: 10px;
  flex: 1;
}
.geolocationOverlayShown {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  margin: auto;
  height: 100%;
  cursor: pointer;
  width: 100%;
}
#geolocationOverlayBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: -1;
  opacity: 0.5;
}
#geolocationOverlayFlex {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#geolocationOverlayMessage {
  color: white;
  padding: 20px;
  background: $header-background;
  border: 2px solid white;
  display: flex;
}
#geolocationOverlayLoader {
  margin-left: 5px;
}
.home {
  background: $channel-background;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
#pageContent {
  height: calc(100% - 50px);
  width: 100%;
  flex: 1;
  display: flex;
}
.map {
  width: 50%;
}
.channel {
  width: 100%;
}
.mapButton {
  display: none;
}

.tabBar {
  display: none !important;
}

#header {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $header-border-color;
  color: white;
  height: 54px;
  margin-top: -50px;
}
@media (max-width: 800px) {
  #header {
    height: initial;
  }
  .mapButton {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    color: white;
  }
  .mapButtonOpened {
    left: 10px;
    color: black;
  }
  .pageContent {
    width: 100%;
    flex: 1;
    display: block;
    position: relative;
    overflow: hidden;
  }
  .map {
    width: 100%;
    position: absolute;
    transform: translateX(100%);
    transition: all 0.8s;
    left: 0;
    top: 0;
  }
  .mapOpened {
    transform: translateX(0%);
  }
  .channel {
    position: absolute;
    transition: all 0.8s;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateX(00%);
  }
  .channelClosed {
    transform: translateX(-100%);
  }
  .tabBar {
    display: flex !important;
  }
}
</style>
