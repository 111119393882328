<template>
  <router-view>
  </router-view>
  
</template>

<script>
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "App",
  components: {},
  metaInfo: {
    meta: [{ name: "viewport", content: "width=device-width, user-scalable=no, maximum-scale=1, initial-scale=1" },
    {name:"theme-color",content:"#36393f"}]
  },
  mounted() {
    const googlemaps = document.createElement("script");
    document.head.appendChild(googlemaps);
    document.querySelector("meta[name='viewport']").setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1, user-scalable=no");
    console.log("initialized viewport");
  }
};
</script>

<style lang="scss">
@import "assets/style.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  background: $main-background;
  padding: 0;
  height: 100%;
}
html {
  height: 100%;
}
</style>
