<template>
  <div class="home">
    <div id="header">
      <WvrzIconButton @click="logout" class="logout" text="logout" />
      <WvrzIconButton @click="profile" class="profile" text="person" />
      <WvrzIconButton @click="settings" class="profile" text="settings" />
      <WvrzIconButton @click="goToCreate" class="profile" text="add_circle" />
      <div v-if="geolocFailed" class="disabled-icon">
        <WvrzIconButton @click="errorLocationDisplay()" class="profile" text="wrong_location" color="#B3B3B3" />
      </div>
    </div>
    <WvrzPopUpNotif
      :title="notifTitle"
      :message="notifMessage"
      :error="notifError"
      :visible="notifVisible"
      @toggleNotificationVisible="toggleNotificationVisible"
    />
    <div id="page">
      <cibi-room
        :style="{ display: $route.params.inside === 'room' ? 'flex' : 'none' }"
        ref="room"
        :position="position"
        :user="user"
        :janusStatus="janusStatus"
        :selected="selected"
        @channelSelected="selectChannel"
        :rooms="rooms"
        @mute ="mute"
        :route="route"
      >
      </cibi-room>
      <cibi-create
        :style="{
          display: $route.params.inside === 'create' ? 'flex' : 'none',
        }"
        :route="route"
        @setNotification="setNotification"
      >
      </cibi-create>
      <cibi-profile
        :style="{
          display: $route.params.inside === 'profile' ? 'flex' : 'none',
        }"
        :user="user"
      >
      </cibi-profile>
      <cibi-settings
        :style="{
          display: $route.params.inside === 'settings' ? 'flex' : 'none',
        }"
        :user="user"
        :position="position"
        :isGeolocationActive="isGeolocationActive"
        :janusStatus="janusStatus"
      >
      </cibi-settings>
    </div>
    <div
      class="geolocationOverlay"
      @click="startGeolocation"
      v-bind:class="{ geolocationOverlayShown: !isGeolocationActive }"
    >
      <div id="geolocationOverlayBackground"></div>
      <div id="geolocationOverlayFlex">
        <div id="geolocationOverlayMessage">
          Connexion en cours<pulse-loader
            id="geolocationOverlayLoader"
            :loading="!isGeolocationActive"
            color="white"
            size="5px"
          ></pulse-loader>
        </div>
      </div>
    </div>

    <cibi-janus-connection
      ref="janus"
      :audioenabled="audioenabled"
      @janusStatusUpdate="janusStatusUpdate"
      :roomId="selected"
      :user="user"
      :janusStatus="janusStatus"
    ></cibi-janus-connection>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import CibiRoom from "./pages/CibiRoom.vue";
import CibiProfile from "./pages/CibiProfile.vue";
import CibiCreate from "./pages/CibiCreate.vue";
import CibiSettings from "./pages/CibiSettings.vue";
import WvrzIconButton from "./generics/WvrzIconButton.vue";
import CibiJanusConnection from "./others/CibiJanusConnection.vue";
import CibiChangeRoom from "./pages/CibiChangeRoom.vue";
import WvrzPopUpNotif from "./generics/WvrzPopUpNotif.vue";

export default {
  components: {
    PulseLoader,
    WvrzIconButton,
    CibiRoom,
    CibiProfile,
    CibiCreate,
    CibiSettings,
    CibiJanusConnection,
    CibiChangeRoom,
    WvrzPopUpNotif,
  },
  name: "Main",
  props: {},
  data() {
    return {
      participants: [],
      chatParticipants: [],
      vocalParticipants: [],
      janusStatus: {},
      started: false,
      user: {},
      isGeolocationActive: false,
      rooms: [],
      watchPositionId: null,
      position: { latitude: 48.52, lng: 2.19 },
      selected: null,
      audio: false,
      isOpen: false,
      audioenabled : true,
      geolocFailed: false,
      notifVisible: false,
      notifError: null,
      notifTitle: "",
      notifMessage: "",
      route : ""
    };
  },
  watch:{
      $route: function(to, from){
        this.route = to.params.inside;
      }
  },
  methods: {
    mute(e){
      this.audioenabled = e;
    },
    janusStatusUpdate(janusStatus){
        this.$chat.sendJanusStatus(janusStatus)
    },
    selectChannel(channel) {
      this.selected = channel.id;
    },
    participantsChangedFromChat(participants) {
      console.log("participants changed from chat", participants);
      this.chatParticipants = participants;
      this.participantsChanged(this.participants);
    },
    participantsChangedFromVocal(x) {
      this.vocalParticipants = x;
    },
    participantsChanged() {
      if (!this.vocalParticipants || !this.chatParticipants) {
        return;
      }
      if (this.selected != null) {
        if (this.chatParticipants && this.chatParticipants.length > 0) {
          this.chatParticipants.forEach(user => {
            user.connected = false;
            this.vocalParticipants.forEach((participant) => {
              if (user.username === participant.display) {
                user.connected = participant.me ? true : participant.setup;
                user.me = participant.me;
                user.muted = participant.muted;
              }
            })
          })
          this.participants = this.chatParticipants;
        }
      }
    },
    startGeolocation() {
      if (!this.user || this.started)
        return;
      this.started = true;
      this.getPosition();
    },
    logout() {
      this.$chat.disconnectChat();
    },
    goToCreate() {
      this.$router.push("/main/create");
    },
    getRooms() {
      console.log("selecting channel00");
      if (this.user.username.toLowerCase() == "admin") {
        this.$room.getRooms((err, data) => {
          this.rooms = data;
          this.rooms.forEach((room) => {
            room.id = room.id + "";
          });
          this.selected = data[0].id;
        });
      } else {
        this.$http._get("/users/" + this.user.id + "/getRooms", (err, data) => {
          if (!err) {
            this.$room.getAutoRoom(this.user.id, (err, autoRoom) => {
              if (autoRoom && autoRoom.room) {
                if (data && data.rooms) {
                  data.rooms.unshift(autoRoom.room);
                } else {
                  data = { rooms: [] };
                  data.rooms.unshift(autoRoom.room);
                }
              }
              data.rooms.forEach((room) => {
                room.id = room.id + "";
              });
              if (!this.selected) {
                this.selected = data.rooms[0].id;
              }
              this.rooms = data.rooms;
            });
          }
        });
      }
    },
    getPosition() {
      var options = {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 120000,
      };
      console.log("Getting position...")
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.syncGeolocationData(pos),
          this.geolocFailed = false;
        },
        (err) => {
          console.error(
            "Main : Could not start geolocation, will retry in 5 seconds",
            err.message
          );
          this.geolocFailed = true;
          setTimeout(() => this.getPosition(), 60000);
        },
        options
      )
    },
    profile() {
      this.$router.push("/main/profile");
    },
    settings() {
      this.$router.push("/main/settings");
    },
    logout() {
      this.$post("/users/logout", null, (err, data) => {
        if (!err) {
          this.$router.push("/");
          sessionStorage.clear();
        }
      });
    },
    selectChannel(channel) {
      this.selectRoom = channel;
      this.selected = channel.id;
    },
    syncGeolocationData(pos) {
      this.isGeolocationActive = true;
      this.position = { lat: pos.coords.latitude, lng: pos.coords.longitude };
      this.$geoloc.updatePosition(this.user, this.position, () => {});
      this.getRooms();
    },
    setNotification({error, title, message}) {
      this.notifVisible = true;
      this.notifError = error;
      this.notifTitle = title;
      this.notifMessage = message;
    },
    toggleNotificationVisible(vis) {
      this.notifVisible = vis;
    },
    errorLocationDisplay() {
      this.setNotification({error: true, title: "Problème de géolocalisation", message: "Un problème avec la géolocalisation est survenu. Nouvelle tentative de géolocalisation..."})
      this.getPosition();
    }
  },
  mounted() {
    this.$get("/users/me", (err, data) => {
      if (!err) {
        this.user = data.user;
        this.startGeolocation();
        var lastChannel = sessionStorage.getItem("channel");
        if (lastChannel !== "null") {
          this.selected = lastChannel;
        }
      }
    });
  },
  unmounted() {
    sessionStorage.setItem("channel", this.selected);
  },
  created() {
    window.addEventListener("resize", this.windowSizeChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChange);
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style.scss";

.geolocationOverlay {
  display: none;
}

.geolocationOverlayShown {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  margin: auto;
  height: 100%;
  cursor: pointer;
  width: 100%;
}

#geolocationOverlayBackground {
  position: absolute;
  top: 50px;
  height: calc(100% - 50px);
  left: 0;
  width: 100%;
  background: #fff;
  z-index: -1;
  opacity: 0.5;
}

#geolocationOverlayFlex {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#geolocationOverlayMessage {
  color: white;
  padding: 20px;
  background: $header-background;
  border: 2px solid white;
  display: flex;
}

#geolocationOverlayLoader {
  margin-left: 5px;
}

.home {
  width: 100%;
  background: $channel-background;
  height: 100%;
  align-items: center;
}

.profile {
  z-index: 10;
  margin-right: 10px;
}

.disabled-icon {
  cursor: pointer !important;
  z-index: 998;
}

.logout {
  z-index: 10;
  margin-right: 10px;
}

#header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background: $header-background;
  border-bottom: 1px solid $header-border-color;
  color: white;
  height: 50px;
}

#page {
  height: calc(100% - 50px);
}

</style>
