<template>
  <div v-if="icon" class="button-style-icon">
    <span class="material-icons">
      {{ text }}
    </span>
  </div>
  <div v-else-if="iconNoAnim" class="button-style-no-anim">
    <span class="material-icons"> {{ text }} </span><br />
  </div>
  <div v-else-if="iconBadge" class="button-style-badge">
    <span class="material-icons"> {{ text }} </span><br />{{ subText }}
  </div>
  <div v-else>
    <button class="button-style" :type="buttonType">
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CibiButton',
  props: {
    text: String,
    subText: String,
    icon: Boolean,
    iconNoAnim: Boolean,
    iconBadge: Boolean,
    buttonType: String
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.button-style-icon {
  color: white;
  background-color: $button-background;
  width: fit-content;
  margin: 10px;
  border-radius: 100%;
  border: solid 0px $button-background;
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, border 0.2s, box-shadow 0.5s,
    transform 0.3s;
}
.button-style-icon:hover {
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.5);
  border: solid 3px $button-background;
  cursor: pointer;
  background-color: $text;
  transform: scale(1.3) perspective(1px);
}
.button-style-no-anim {
  color: white;
  width: fit-content;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, border 0.2s, box-shadow 0.5s;
}
.button-style-no-anim:hover {
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.button-style-badge {
  color: $text;
  padding: 5px;
  font-size: small;
  width: fit-content;
  min-width: 65px;
  text-align: center;
  border-radius: 10px;
  border: solid 2px rgba(0, 0, 0, 0);
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, border 0.2s, box-shadow 0.5s, color 0.2s;

  .material-icons {
    font-size: 16pt;
  }
}
.button-style-badge:hover {
  color: white;
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.5);
  border: solid 2px $button-background;
  cursor: pointer;
  background-color: $text;
}
.button-style {
  color: $button-color;
  background-color: $button-background;
  width: fit-content;
  margin: 10px;
  padding: 10px;
  font-size: 10pt;
  border-radius: 5px;
  border: solid 0px $button-background;
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, border 0.5s, box-shadow 0.5s, margin 0.5s,
    transform 0.5s;
  box-sizing: border-box;
}
.button-style:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: $button-background;
}
.material-icons {
  padding: 5px;
}
</style>
