<template>
  <div id="channelsList">
    <WvrzIconButton @click="isOpen=true" text="forum" class="burger-menu-button" />
    <div class="channel">
      {{ channels.find((x)=> x.id === selectedChannel)?.name }}
    </div>

    <Slide class="slide-menu" :isOpen="isOpen" @closeMenu="isOpen = false" :burgerIcon="false" :crossIcon="false"
      :closeOnNavigation="true" overlay="true" :width="menuWidth">
      <cibi-change-room :rooms="rooms" :selected="selectedChannel" @channelSelected="selectChannel" />
    </Slide>
    <div id="menu-overlay" v-if="isOpen === true"></div>

    <!-- <div v-if="showHidden" class="hiddenChannels">
      <div class="hiddenChannel" @click="
        () => {
          select(element);
        }
      " v-for="element in _hiddenChannels" :key="element.id"
        v-bind:class="{ selected: isSelected(element, selectedChannel) }">
        {{ element.name }}
      </div>
    </div> -->
  </div>
</template>

<script>
import WvrzIconButton from "../generics/WvrzIconButton.vue";
import { Slide } from 'vue3-burger-menu'
import CibiChangeRoom from "../pages/CibiChangeRoom.vue";

export default {
  name: "CibiChannelsList",
  emits: ["channelSelected"],
  components: {
    WvrzIconButton,
    Slide,
    CibiChangeRoom
  },
  data() {
    return {
      _channels: [],
      _hiddenChannels: [],
      showHidden: false,
      dataSelectedChannel: Object,
      isOpen: false,
      windowWidth: window.innerWidth,
      menuWidth: "300",
    };
  },
  props: {
    channels: {
      default: [],
      type: Array,
    },
    selectedChannel: String,
    rooms: Array
  },
  watch: {
    channels: function (newValue, old) {
      if (newValue && newValue.length > 3) {
        var tmp = Array.from(newValue);
        this._channels = tmp.slice(0, 3);
        this._hiddenChannels = tmp.slice(3, tmp.length);
      } else {
        this._channels = newValue;
        this._hiddenChannels = [];
      }
    },
  },
  methods: {
    select(element) {
      this.$emit("channelSelected", element);
    },
    isSelected: function (element, selectedChannel) {
      return selectedChannel === element.id;
    },
    reloadChannnels() {
      if (this.channels && this.channels.length > 3) {
        var tmp = Array.from(this.channels);
        this._channels = tmp.slice(0, 3);
        this._hiddenChannels = tmp.slice(3, tmp.length);
      } else {
        this._channels = this.channels;
        this._hiddenChannels = [];
      }
    },
    changeroom() {
      this.$router.push("/main/changeroom");
    },
    selectChannel(room) {
      this.$emit("channelSelected", room);
      this.isOpen = false;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (window.innerWidth >= 800)
        this.menuWidth = "300";
      else
        this.menuWidth = window.innerWidth.toString();
    }
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    /*var newValue = this.channels;
    if (this.channels && this.channels.length > 0) {
      if (newValue && newValue.length > 3) {
        var tmp = Array.from(newValue);
        this._channels = tmp.slice(0, 3);
        this._hiddenChannels = tmp.slice(3, tmp.length);
      } else {
        this._channels = newValue;
        this._hiddenChannels = [];
      }
    }*/
    // dataSelectedChannel = this.channels.find((x) => x.id === this.selectedChannel)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

#channelsList {
  width: calc(100% - 83px);
  color: $text-color;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.channel {
  margin: 5px;
  padding: 10px;
  align-items: center;
  color: $text-color-bar;
  font-family: $font-family;
  cursor: pointer;
  border: 1px solid $channel-border-color;
  border-radius: 5px;
  max-width: calc(33.33% - 54px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hiddenChannel {
  margin: 5px;
  padding: 10px;
  align-items: center;
  color: $text-color-bar;
  font-family: $font-family;
  cursor: pointer;
  border: 1px solid $channel-border-color;
  border-radius: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected {
  background: $selected-channel-background;
  border-radius: 5px;
  color: $selected-text-color;
}

.hiddenChannels {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: $background;
  z-index: 1;
  width: 50%;
  max-width: 400px;
  top: 56px;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: auto;
}

.hiddenChannels::-webkit-scrollbar {
  width: 1em;
}

.hiddenChannels::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $selected-channel-background;
}

.hiddenChannels::-webkit-scrollbar-thumb {
  background-color: $selected-channel-background;
  outline: 1px solid $channel-border-color;
}

.slide-menu :deep(.bm-menu) {
  background: white !important;
}

.slide-menu :deep(.bm-item-list) {
  margin-left: 0;
}

.burger-menu-button {
  margin-top: 5px;
  margin-left: 5px;
}

#menu-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

@media (max-width: 800px) {
  .hiddenChannels {
    left: 50%;
    transform: translate(-50%, 0);
  }

  .channel {
    font-size: 10pt;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 33.33%;
  }

  .hiddenChannel {
    font-size: 10pt;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 33.33%;
  }

  // .slide-menu :deep(.bm-menu) {
  //   width: 100vh;
  // }
}
</style>
