<template>
  <input
    :type="typeInput"
    :placeholder="placeholderText"
    v-model="inputValue"
  />
</template>

<script>
export default {
  name: 'CibiInput',
  props: {
    placeholderText: String,
    typeInput: String
  },
  data () {
    return {
      inputValue: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
input {
  font-family: "Roboto";
  font-weight: bolder;
  padding: 5px 10px;
  margin: 5px 15px;
  width: calc(100% - 52px);
  background-color: $input-background;
  border: solid 1px rgba(255, 255, 255, 0);
  transition: background-color 0.3s, border-bottom 0.2s, border-radius 0.2s,
    border 0.3s;
  border-bottom: solid 1px rgb(148, 171, 192);
  color: $input-color;
}
input:hover{
    outline: none;
    background-color: $input-hover-background;
}
input:focus {
  outline: none;
}
input::placeholder {
  color: $input-placeholder-color;
  transition: color 0.2s, visibility 0.2s;
}
input:hover::placeholder {
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

</style>
