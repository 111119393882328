<template>
    <div class="reset-password-demand">

    <div class="login">
        <wvrz-button class="button-back" iconNoAnim text="arrow_back" @click="backLogin"></wvrz-button>
        <img
        id="login-logo"
      src="../../../assets/logo.svg"
        alt="Citizen Band by Weaverize"
        />
        <h2>Re-initialisation du mot de passe</h2>
        <p v-if="!emailSent">Veuillez indiquer l'adresse email liée à votre compte.</p>
        <form v-if="!emailSent" @submit.prevent="reset">
            <wvrz-input placeholderText="Votre adresse email" required="required" type="email" v-model="email"></wvrz-input>
            <input id="reset" type="submit" value="reset" hidden />
            <wvrz-button for=reset text="Envoyer" buttonType="submit"></wvrz-button>
        </form>
        <p v-if="emailSent">
            Nous vous avons envoyé un email afin de procéder à la ré-initialisation de votre mot de passe.
            Si vous ne le voyez pas, pensez à vérifier vos courriers indésirables.
        </p>
    </div>
    </div>
</template>

<script>
import WvrzInput from '../../generics/WvrzInput.vue'
import WvrzButton from '../../generics/WvrzButton.vue'
export default {
  components: { WvrzInput, WvrzButton },
  name: 'CibiDemandPassword',
  data () {
    return {
      email: undefined,
      emailSent: false
    }
  },
  methods: {
    reset () {
      this.$users.resetPassword(this.email, (err, data) => {
        console.log(err, data)
        if (!err) {
          this.emailSent = true
        }
      })
    },
    backLogin () {
      this.$router.push('/')
    }
  },
  mounted () {}
}
</script>

<style scoped lang="scss" >
@import "../../../assets/style.scss";

.login{
    background-color: $login-background;
    color: $login-text-color ;
    margin: 0 auto 0 auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    max-width: 500px;
    border-radius: 20px;
    font-family: $font-family;
    position: relative;
}

.reset-password-demand {
  padding-top: 100px;
}
@media (max-width: 800px) {
  .login {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .login {
    width: 100%;
    margin : 0;
    height : 100%;
  border-radius: 0;
  }
  .reset-password-demand {
  padding-top: 0px;
  height : 100%;
  overflow-y: auto;
  background : $login-background;
}
}
#login-logo {
    max-width: 60%;
    margin-top: 40px;
    margin-bottom: 40px;
  max-height: 100px;
}

form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

input {
    margin-bottom: 20px;
    font-family: $font-family;
    width: 100%;
}

.reset-button {
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: white;
    border-radius: 10px;
}

h2{
    color: $login-text-color;
    width: 100%;
    text-align: center;
}

p{
    text-align: center;
    font-size:small;
}

.button-back{
  position: absolute;
  left:10px;
  top:10px;
}
</style>
