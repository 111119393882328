const utils = require('./utils.js')


var BASE_URL = 'http://localhost:3000/api'

//if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://api.citizen-band.weaverize.com/api'
//}

function _getUrl (url) {
  var token = sessionStorage.getItem('user_token')
  var apiUrl = BASE_URL + url

  if (token) {
    if (apiUrl.includes('?')) { apiUrl += `&access_token=${token}` } else { apiUrl += `?access_token=${token}` }
  }

  return apiUrl
}

function fetchJson (url, config, cb) {
  return fetch(_getUrl(url), config).then(response => {
    (async () => {
      if (response.status === 204) {
        cb(null, true)
      } else if (response.status === 200) {
        var data = await response.json()
        cb(null, data)
      } else if (response.status === 401) {
        var error = await response.json()
        console.log(response.status, error.error.message)
        utils.error('Erreur', `${response.status} : ${error.error.message}`)
        sessionStorage.clear()
        setTimeout(() => { window.location.reload() }, 1000)
      } else {
        var err = await response.json()
        console.log(response.status, err.error.message)
        utils.error('Erreur', `${response.status} : ${err.error.message}`)
        cb(err.error, null)
      }
    })()
  }).catch(error => {
    console.log(error)
    utils.error('Erreur', error.message)
    cb(error, null)
  })
}

function sendRequest (method, url, body, cb, noStringify, noHeaders) {
  var headers = new Headers()
  headers.append('Content-type', 'application/json')
  headers.append('Accept', 'application/json')

  var config = { method }

  if (!noHeaders) {
    config.headers = headers
  }
  if (body) {
    config.body = noStringify ? body : JSON.stringify(body)
  }

  return fetchJson(url, config, cb)
}

function _get (url, cb) {
  sendRequest('GET', url, null, cb)
}

function _postFile (url, file, id, duration, progress, success, error) {
  var xhr = new XMLHttpRequest()
  var formData = new FormData()
  formData.append('file', file)

  xhr.upload.onerror = error
  xhr.upload.onprogress = (event) => {
    progress(id, Math.round((event.loaded / event.total) * 100))
  }

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      var newFile = JSON.parse(xhr.response)
      _patch(`/medias/${newFile.id}`, { duration }, (err, data) => {
        if (err) {
          utils.error('Erreur', "Une erreur est survenue lors de l'upload du fichier")
          error()
        } else {
          utils.success('Succès', `${data.name} uploadé avec succès`)
          success(id)
        }
      })
    }
  }

  xhr.open('POST', _getUrl(url))
  xhr.send(formData)
}

function _post (url, body, cb, noStringify) {
  sendRequest('POST', url, body, cb, noStringify)
}

function _put (url, body, cb, noStringify) {
  sendRequest('PUT', url, body, cb, noStringify)
}

function _patch (url, body, cb, noStringify) {
  sendRequest('PATCH', url, body, cb, noStringify)
}

function _delete (url, cb) {
  sendRequest('DELETE', url, null, cb, false)
}
function _createEventSource(url,cb){
  cb(new EventSource(_getUrl(url)));
}

function _createWebSocket(url){
  const socket = new WebSocket(_getUrl (url).replace("http","ws"));
  return socket;
}

module.exports = { _get, _post, _put, _patch, _delete, _postFile, _getUrl,_createEventSource,_createWebSocket, BASE_URL }
