<template>
  <div class="page">
    <WvrzIconButton @click="backMain" class="logout" text="arrow_back" />
    <div v-if="user" class="profil">
      <div class="parameters">
        <div class="param">
          <div :class="isGeolocationActive?'green':'red'"></div>
          <label>
            Geolocalisation :
            {{ isGeolocationActive ? "Active" :"Inactive" }}</label
          >
        </div>
        <hr />
        <div class="param">
          <div :class="position?'green':'red'"></div>
          <label> Position : {{ position.lat }} , {{ position.lng }}</label>
        </div>
        <hr />
        <div class="param">
          <div :class="isMicrophoneActive===null?'grey':isMicrophoneActive?'green':'red'"></div>
          <label> Micro : {{ isMicrophoneActive===null?"En cours...":isMicrophoneActive?"Actif":"Inactif" }}</label>
        </div>
        <hr />
        <div class="param">
          <div :class="user.id?'green':'red'"></div>
          <label> Serveur API : {{ user.id?"Actif":"Inactif"}}</label>
        </div> <hr />
        <div class="paramWithsubs">
          
          <label class="param"> <div :class="typeof(janusStatus.started)==='undefined'?'grey':janusStatus.started?'green':'red'"></div>Serveur Janus </label>
          <div class="subParam">
            <div :class="typeof(janusStatus.started)==='undefined'?'grey':janusStatus.started?'green':'red'"></div>
            <label> Démarré </label>
            </div>
            <div class="subParam">
            <div :class="typeof(janusStatus.attached)==='undefined'?'grey':janusStatus.attached?'green':'red'"></div>
            <label> Attaché </label>
            </div>
            <div class="subParam">
            <div :class="typeof(janusStatus.localStream)==='undefined'?'grey':janusStatus.localStream?'green':'red'"></div>
            <label> Stream Local </label>
            </div>
            <div class="subParam">
            <div :class="typeof(janusStatus.remoteStream)==='undefined'?'grey':janusStatus.remoteStream?'green':'red'"></div>
            <label> Stream Distant </label>
            </div>
            <div class="subParam">
            <div :class="typeof(janusStatus.webRtcStarted)==='undefined'?'grey':janusStatus.webRtcStarted?'green':'red'"></div>
            <label> WebRtc Démarré </label>
            </div>
            <div class="subParam">
            <div :class="typeof(janusStatus.iceState)==='undefined'?'grey':janusStatus.iceState==='connected'?'green':'red'"></div>
            <label> Ice : {{janusStatus.iceState}}</label>
            </div>

            <div class="subParam">
            <div :class="typeof(janusStatus.audioenabled)==='undefined'?'grey':janusStatus.audioenabled?'green':'red'"></div>
            <label> Audio activé</label>
            </div>
            <div class="subParam">
            <div :class="typeof(janusStatus.lastMessage)==='undefined'?'grey':janusStatus.lastMessage?'green':'red'"></div>
            <label> Dernier message : {{janusStatus.lastMessage}}</label>
            </div>
            
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WvrzIconButton from '../generics/WvrzIconButton.vue'

export default {
  name: 'CibiSettings',
  components: { WvrzIconButton },

  props: {
    janusStatus : Object,
    position : Object,
    isGeolocationActive : Boolean,
    user : Object
  },
  data () {
    return {
      isMicrophoneActive: null
    }
  },
  mounted () {
    this.getMicrophoneStatus();
    console.log("ok")
  },
  
  methods: {
    getMicrophoneStatus(){
        this.isMicrophoneActive = null;
        navigator.mediaDevices.getUserMedia({ audio: true, video: false }
    )
    .then((stream)=> {
      this.isMicrophoneActive = true;
    })
    .catch((err)=> {
      this.isMicrophoneActive = false;
    });
  },
    backMain () {
      this.$router.push('/main/room')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.page {
  background: $dark-background;
  width: 100%;
  height: 100%;
}
.grey{
  width : 20px;
  height : 20px;
  margin : 10px;
  border-radius : 20px;
  background  : grey;
}
.subParam{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left : 10px;
}
.paramWithsubs{
  font-size: medium;
  color: $text-darker;
}
.green{
  width : 20px;
  height : 20px;
  margin : 10px;
  border-radius : 20px;
  background  : #2eaf05;
}

.red{
  width : 20px;
  height : 20px;
  margin : 10px;
  border-radius : 20px;
  background  : red;
}
.profil {
  font-family: $font-family;
  font-size: large;
  max-width: 800px;
  width: 40%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  border-radius: 10px;
  transition: box-shadow 0.5s;
}

@media (max-width: 800px) {
  .profil {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .profil {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
  }
}
.param-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title {
  font-size: large;
  margin-right: auto;
  margin-left: auto;
  color: $text-darker;
  padding-right: 32px;
}
.parameters {
  background-color: $create-room-background;
  border-radius: 10px;
  margin: 20px;
}
.param {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: medium;
  color: $text-darker;
}

.material-icons {
  color: $text-darker;
  margin: 10px;
}
hr {
  margin: 0;
  border-color: $header-background;
}
hr:last-child {
  display: none;
}
.logout {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
