<template>
  <div
    id="pagesContainer"
    v-bind:style="{
      '--current-page': currentPage,
      '--pages-number': pagesNumber
    }"
  >
    <div id="pages" class="wvrzSliderPages">
      <slot></slot>
    </div>
  <WvrzIconButton
    @click="next"
    v-bind:class="{ hidden: !hasNext, hiddenLarge: !hasNextLarge }"
    class="nextButton"
    text="navigate_next"
  />
  <WvrzIconButton
    @click="previous"
    v-bind:class="{ hidden: !hasPrevious }"
    class="previousButton"
    text="navigate_before"
  />
  </div>
</template>
<script>
import WvrzIconButton from "./WvrzIconButton.vue";

import { toRef } from "vue";
export default {
  components: { WvrzIconButton },
  name: "WvrzSlider",
  data() {
    return {
      pagesNumber: 0,
      currentPage : 0
    };
  },

  props: {
    initialPage: {
      default: 0,
      type: Number
    },
    page : {
      type : Number
    }
  },
  computed: {
    hasPrevious: function() {
      return this.currentPage !== 0;
    },
    hasNext: function() {
      return this.currentPage + 1 !== this.pagesNumber;
    },
    hasNextLarge: function() {
      return this.currentPage + 2 !== this.pagesNumber;
    }
  },

  watch: {
    page: function(tabs) {
      this.currentPage = this.page;
    }
  },
  methods: {
    next() {
      this.currentPage++;
    },
    previous() {
      this.currentPage--;
    }
  },
  mounted() {
    this.pagesNumber = this.$slots.default().length;
    this.currentPage = this.initialPage;
    var w = window.innerWidth;
      if (w>800){
        this.currentPage = 0;
      }else{
        this.currentPage = 1;
      }
  }
};
</script>

<style lang="scss">
  .wvrzSliderPages > * {
    width: calc(100% / var(--pages-number)) !important;
  }
@media (min-width: 800px) {

.wvrzSliderPages > * {
  width: calc(40% / var(--pages-number)) !important;
}

.wvrzSliderPages>div:first-child{
  width: calc(20% / var(--pages-number)) !important;
}
}
</style>
<style lang="scss" scoped>
@import "../../assets/style.scss";
.nextButton {
  display: block;
  display : none;
  position: absolute;
  right: 10px;
  top: 50%;
  color: white;
}

.previousButton {
  display: block;
  display : none;
  position: absolute;
  left: 10px;
  top: 50%;
  color: white;
}
#pages {
  height: 100%;
  display: flex;
  transition: transform 0.8s;
  width: calc(var(--pages-number) * 100%);
  transform: translateX(calc(var(--current-page) * -50% / var(--pages-number)));
}

.hidden {
  display: none;
}

.hiddenLarge {
  display: none;
}

#pagesContainer {
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
}
#pages > * {
  width: 25%;
  height: 100%;
}
@media (max-width: 800px) {
  #pages {
    transform: translateX(
      calc(var(--current-page) * -100% / var(--pages-number))
    );
  }
  .hiddenLarge {
    display: block;
  }
}
</style>
