<template>
  <div class="page">
    <WvrzIconButton @click="backMain" class="arrow-icon" text="arrow_back" color="black" />
    <div id="rooms-container">
      <div v-for="(room) in rooms" class="list-element"
        :class="{selected: room.id === this.selected}" @click="changeRoom(room)">
        {{room.name}}
      </div>
    </div>
  </div>
</template>

<script>
import WvrzIconButton from '../generics/WvrzIconButton.vue'

export default {
  components: {
    WvrzIconButton,
  },
  props: {
    rooms: Array,
    selected: String,
  },
  methods: {
    backMain() {
      this.$router.push('/main/room')
    },
    changeRoom(element) {
      this.$emit("channelSelected", element);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

.page {
  min-width: 300px;
  width: 100%;
  height: 100%;
}

.arrow-icon {
  position: absolute;
  left: 0px;
  top: 10px;
}

.list-element {
  color: $text-color;
  background: rgb(193, 224, 240);
  color: black;
  margin-bottom: 1px;
  width: 100%;
  padding: 15px;
  cursor: pointer;
}

.list-element:nth-child(even) {
  background: white;
}

.selected {
  background-color: $dark-background !important;
  border: none;
  color: white;
}

#rooms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
}
</style>