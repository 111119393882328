<template>
  <div class="loginWindow">
    <div class="login">
      <img
        id="login-logo"
        src="../../../assets/logo.svg"
        alt="Citizen Band by Weaverize"
      />
      <form @submit.prevent="login" class="login-form">
        <wvrz-input
          placeholderText="Nom"
          v-model="username"
          typeInput="text"
        ></wvrz-input>
        <wvrz-input
          typeInput="password"
          placeholderText="Mot de passe"
          v-model="password"
        ></wvrz-input>
        <router-link class="forgot-password" to="/reset">
          Mot de passe oublié ?
        </router-link>
        <input id="login" type="submit" value="Connexion" hidden />
        <wvrz-button
          for="login"
          text="Connexion"
          buttonType="submit"
        ></wvrz-button>
      </form>
      <div class="register">
        <input id="register" type="submit" value="S'inscrire" hidden />
        <label class="register-text">Pas de compte ?</label>
        <label @click="register" class="register-link" for="register"
          >S'inscrire</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import WvrzInput from "../../generics/WvrzInput.vue";
import WvrzButton from "../../generics/WvrzButton.vue";
export default {
  name: "CibiLogin",
  props: {},
  components: { WvrzInput, WvrzButton },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      var credentials = {
        username: this.username,
        password: this.password,
      };
      this.$login(credentials, (err, data) => {
        if (!err) {
          this.$router.push("/main/room");
        } else {
          sessionStorage.clear();
          this.$router.push("/");
        }
      });
    },
    register() {
      this.$router.push("/register");
    },
    reset() {
      this.$router.push("/reset");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style.scss";

.login {
  background-color: $login-background;
  color: $login-text-color ;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  max-width: 500px;
  border-radius: 20px;
  font-family: $font-family;
}

.loginWindow {
  padding-top: 100px;
}
@media (max-width: 800px) {
  .login {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .login {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
  }

.loginWindow {
  padding-top: 0px;
  height : 100%;
  overflow-y: auto;
  background : $login-background;
}
}
h1 {
  font-size: 20px;
  margin-top: 20px;
}

#login-logo {
  max-width: 80%;
  max-height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.login-form {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgot-password {
  font-size: small;
  margin-bottom: 10px;
  width: 80%;
  text-align: center;
  color: $login-link-color;
}

input {
  margin-bottom: 20px;
  font-family: $font-family;
  width: 80%;
}
.login-button {
  margin-top: 18px;
  padding: 10px;
  align-items: center;
  margin-bottom: 20px;
  color: $button-color;
  border-radius: 10px;
  width: 60%;
  text-align: center;
}


.register-link {
  text-decoration: underline;
  color: $login-link-color;
}

.register-text {
  margin-right: 10px;
  color: $login-text-color;
}

.register {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: small;
}
</style>
