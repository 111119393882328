<template>
  <div v-if="visible" id="notif-container">
    <div id="info-container">
      <div id="indicator" :style="cssVars"></div>
      <div id="notif-text-container">
        <div id="title-container">{{title}}</div>
        <div id="message-container">{{message}}</div>
      </div>
      <WvrzIconButton @click="toggleVisible()" id="close-notif" text="close" color="#B3B3B3" />
      <div class="meter">
        <span style="width:97%;"><span class="progress"></span></span>
      </div>
    </div>
  </div>
</template>

<script>
import WvrzIconButton from "./WvrzIconButton.vue";

export default {
  name: "PopUpNotif",
  components: {
    WvrzIconButton
  },
  props: {
    title: String,
    message: String,
    error: Boolean,
    visible: Boolean,
  },
  watch: {
    visible(newVisible, oldVisible) {
      if (newVisible === true)
        setTimeout(this.toggleVisible, 10000)
    }
  },
  computed: {
    cssVars() {
      if (this.error === true)
        return { '--background-color': 'red' }
      else if (this.error === false)
        return { '--background-color': 'green' }
      else
        return { '--background-color': 'blue' }
    }
  },
  methods: {
    toggleVisible() {
      this.$emit("toggleNotificationVisible", false)
    }
  }
}
</script>

<style type="scss" scoped>
#notif-container {
  min-width: 250px;
  min-height: 50px;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 0px;
  margin: 10px;
  background-color: #F1F1F1;
  z-index: 999;
  border-radius: 5px;
}

#info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

#indicator {
  width: 10px;
  background-color: var(--background-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#notif-text-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

#title-container {
  font-weight: bold;
  font-size: 16px;
}

#message-container {
  font-size: 14px;
}

#close-notif {
  margin: 5px;
  cursor: pointer;
}

.meter {
  height: 2px;
  width: 100%;
  position: relative;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 10px;
}

.meter span {
  display: block;
  height: 100%;
  width: 100%;
}

.progress {
  background-color: #3e87e3;
  animation: progressBar 10s linear;
  animation-fill-mode: both;
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>