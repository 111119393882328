var httpUtils = require('./http-utils.js')
var currentChatRoom = null;
var socket = null;
var pingpong = null;
var savedRoomId = null ;
var savedUserId = null ;
var timer = null;
var savedMessageCallback = null;
var running = false;

function connectToChat(roomId,userId, messageCallback){
    running = true;
    savedRoomId = roomId;
    savedUserId = userId;
    savedMessageCallback = messageCallback;
    if(socket){
        socket.removeEventListener("message", messageFunction);
        socket.removeEventListener("error", errorFunction);
        socket.close();
        socket = null;
    }
    if(timer){
        clearInterval(timer);
    }
    socket = httpUtils._createWebSocket('/rooms/'+roomId +"/users/"+userId+ "/createWebsocket/")
    socket.addEventListener('error', errorFunction)
    socket.addEventListener('message', messageFunction);
    timer= setInterval(sendPing,10000);
} 



function errorFunction(event){
    if(running){
        connectToChat(savedRoomId,savedUserId,savedMessageCallback);
    }
    
}
function messageFunction(event){
    var parsedMessage = JSON.parse(event.data);
    if(parsedMessage.type === "pong"){
    }else{
        if(savedMessageCallback){
            savedMessageCallback(event.data);
        }
    }
}


function sendPing(){
    if(!running){
        return;
    }
    if(socket && socket.readyState !==  socket.CLOSED){
        if(socket.readyState === socket.OPEN){
            socket.send(JSON.stringify({type : "ping",value : "ping"}));
        }
    }else{
            connectToChat(savedRoomId,savedUserId,savedMessageCallback);
    }
}
function disconnectChat(){
    running = false;
    if(socket){
        socket.close();
        socket = null;
    }
}



function sendJanusStatus(janusStatus){
    if(!running){
        return;
    }
    if(socket && socket.readyState !==  socket.CLOSED){
        if(socket.readyState === socket.OPEN){
            socket.send(JSON.stringify({type : "janus",value : janusStatus}));
        }else{
            setTimeout(()=>{sendJanusStatus(janusStatus)},1000);
        }
    }else{
        connectToChat(savedRoomId,savedUserId,savedMessageCallback);
    }
}


function sendMessage(roomId,username,message,cb){
    if(!running){
        return;
    }
    if(socket && socket.readyState !==  socket.CLOSED){
        if(socket.readyState === socket.OPEN){
            socket.send(JSON.stringify({type : "chat",value : { username:username,message:message }}));
            cb(null);
        }else{
            setTimeout(()=>{sendMessage(roomId,username,message,cb)},1000);
        }
    }else{
        connectToChat(savedRoomId,savedUserId,savedMessageCallback);
    }
    
}


module.exports = { connectToChat ,sendMessage, sendJanusStatus,disconnectChat }