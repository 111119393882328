<template>
  <span id="button" class="material-icons" :style="cssVars">
    {{ text }}
  </span>

</template>
<script>
export default {
  name: 'WvrzIconButton',
  data() {
    return {}
  },
  props: {
    color: String,
    fontColor: String,
    text: String
  },
  computed: {
    cssVars() {
      return {
        '--color': this.color,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

#button {
  border: 0px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  background: $icon-button-background;
  color: var(--color);
  font-size: 20pt;
  border-radius: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
