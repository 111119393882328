<template>
  <div class="register-form">
  <div class="login">
    <wvrz-button
      id="button-back"
      iconNoAnim
      text="arrow_back"
      @click="backLogin"
    ></wvrz-button>
    <img
      id="login-logo"
      src="../../../assets/logo.svg"
      alt="Citizen Band by Weaverize"
    />
    <h2 v-if="!emailSent">Créer un compte</h2>
    <form v-if="!emailSent" @submit.prevent="register">
      <wvrz-input
        placeholderText="Votre nom d'utilisateur"
        v-model="name"
        required
      ></wvrz-input>
      <wvrz-input
        placeholderText="Votre adresse email"
        type="email"
        v-model="email"
        required
      ></wvrz-input>
      <input id="register" type="submit" value="register" hidden />
      <wvrz-button
        for="register"
        text="S'inscrire"
        buttonType="submit"
      ></wvrz-button>
    </form>
    <div class="confirm" v-if="emailSent">
      <h2>Email envoyé !</h2>
      <p>
        Pour confirmer la création de votre compte, vérifiez votre boite mail
        puis cliquez sur le lien d'activation que nous vous avons envoyé, afin
        de définir votre mot de passe.
      </p>
      <p>
        Si vous ne voyez pas l'email en question, pensez à vérifier vos
        courriers indésirables.
      </p>
    </div>
  </div>
  </div>
</template>

<script>
import WvrzButton from '../../generics/WvrzButton.vue'
import WvrzInput from '../../generics/WvrzInput.vue'

export default {
  name: 'CibiRegister',
  components: { WvrzButton, WvrzInput },
  data () {
    return {
      name: undefined,
      email: undefined,
      emailSent: false
    }
  },
  methods: {
    register () {
      this.$users.register(this.name, this.email, (err, data) => {
        if (!err) {
          this.emailSent = true
        }
      })
    },
    backLogin () {
      this.$router.push('/')
    }
  },
  mounted () {}
}
</script>

<style scoped lang="scss">
@import "../../../assets/style.scss";

.register-form {
  padding-top : 100px;
}
.login{
    background-color: $login-background;
    color: $login-text-color ;
    margin: 0 auto 0 auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    max-width: 500px;
    border-radius: 20px;
    font-family: $font-family;
    position: relative;
}

@media (max-width: 800px) {
  .login  {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .login {
    width: 100%;
    margin : 0;
    height : 100%;
  border-radius: 0;
  }
  .register-form {
  padding-top: 0px;
  height : 100%;
  overflow-y: auto;
  background : $login-background;
}
}
#login-logo {
  max-width: 60%;
  margin-top: 40px;
  margin-bottom: 20px;
  max-height: 100px;
}

form {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

input {
  margin-bottom: 20px;
  font-family: $font-family;
  width: 100%;
}

.register-button {
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: $login-button-color;
  border-radius: 10px;
}

label:hover {
  cursor: pointer;
}


h2 {
  color: $login-text-color;
  width: 100%;
  text-align: center;
}
p {
  font-size: small;
}
.confirm {
  text-align: center;
  width: 80%;
  h2 {
    width: 100%;
  }
}
#button-back {
  position: absolute;
  left:10px;
  top:10px;
}
</style>
