<template>
  <div class="page">
    <WvrzIconButton @click="backMain" class="logout" text="arrow_back" />
    <div v-if="user" class="profil">
      <div class="parameters">
        <div class="param">
          <div class="material-icons">person</div>
          <label> Utilisateur: {{ user.username }}</label>
        </div>
        <hr />
        <div class="param">
          <div class="material-icons">mail</div>
          <label> Email: {{ user.email }}</label>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>

import WvrzIconButton from '../generics/WvrzIconButton.vue'

export default {
  components: { WvrzIconButton },
  name: 'CibiProfile',
  props: {
    user : Object
  },
  methods: {
    backMain () {
      this.$router.push('/main/room')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.page{
  background : $dark-background;
  width : 100%;
  height : 100%;
}
.profil {
  font-family: $font-family;
  font-size: large;
  max-width: 800px;
  width: 40%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  border-radius: 10px;
  transition: box-shadow 0.5s;
}

@media (max-width: 800px) {
  .profil {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .profil {
    width: 100%;
    margin : 0;
    height : 100%;
    border-radius: 0;
  }
}
.param-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title {
  font-size: large;
  margin-right: auto;
  margin-left: auto;
  color: $text-darker;
  padding-right: 32px;
}
.parameters {
  background-color: $create-room-background;
  border-radius: 10px;
  margin: 20px;
}
.param {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: medium;
  color: $text-darker;
}

.material-icons {
  color: $text-darker;
  margin: 10px;
}
hr {
  margin: 0;
  border-color: $header-background;
}
hr:last-child {
  display: none;
}
.logout {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
