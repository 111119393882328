<template>
  <div id="map">

    <mapbox-map class="fullMap"
      @click="addMarker"
      :center="[2,47.41322]"
      :zoom="zoom"
      accessToken="pk.eyJ1Ijoid2VhdmVyaXplIiwiYSI6ImNsOHNxOGw4NDNnZTIzd281aDRzMHBmNGwifQ.Foztgsd2GeyswcrorktEQg"
    >
      <mapbox-marker
        draggable
        v-for="(m, index) in markers"
        :key="index"
        :lngLat="m.pos"
        @dragend="
          (e) => {
            this.markerUpdate(m,e);
          }
        "
        @click="
          (e) => {
            this.markerClick(index,e);
          }
        "
      />
      <mapbox-geogeometry-polygon fillColor="rgba(00, 00, cc, 0.4)"  :path="getPolygon(markers)" />
    </mapbox-map>
  </div>
</template>
<script>
import {
  MapboxMap,
  MapboxGeogeometryPolygon,
  MapboxMarker,
} from "vue-mapbox-ts";
export default {
  name: "CibiCreateRoomMap",
  components: {
    MapboxMap,
    MapboxGeogeometryPolygon,
    MapboxMarker,
  },
  data() {
    return {
      map: null,
      zoom: 4,
      markerId: 0,
    };
  },
  props: {
    room: Object,
    markers: Array,
    route: String,
  },

  watch: {
    route: function(to, from){
      if(to==="create"){
        setTimeout(()=>{window.dispatchEvent(new Event('resize'))},500);
        console.log("resizing")
      }
    }
  },
  methods: {
    markerClick(index,e) {
      this.markers.splice(index, 1);
      e.stopPropagation();
    },
    markerUpdate(element, a) {
      element.pos = [a.target._lngLat.lng, a.target._lngLat.lat];
    },
    addMarker(a, b) {
      if (a.lngLat) {
        this.markers.push({ pos: [a.lngLat.lng, a.lngLat.lat] });
      }
    },
    getPolygon(markers) {
      var ret = [];
      this.markers.forEach((p) => {
        ret.push([p.pos[0], p.pos[1]]);
      });
      if (this.markers.length > 0) {
        ret.push(([this.markers[0].pos[0], this.markers[0].pos[1]]));
      }
      return ret;
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.fullMap {
  width: 100%;
  height: 100%;
}
.lmap {
  width: 100%;
  height: 100%;
}
</style>
