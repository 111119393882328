<template>
  <div class="register-password">
  <div class="login">
    <img
      id="login-logo"
      src="../../../assets/logo.svg"
      alt="Citizen Band by Weaverize"
    />
    <h2>Confirmez votre mot de passe</h2>
    <p>
      Veuillez indiquer un mot de passe pour votre compte. Prenez soin de
      choisir un mot de passe difficile à deviner.
    </p>
    <form @submit.prevent="confirm">
      <wvrz-input
        placeholderText="Votre mot de passe"
        oncopy="return false"
        type="password"
        v-model="password"
        required
      ></wvrz-input>
      <wvrz-input
        placeholderText="Confirmer le mot de passe"
        onpaste="return false"
        type="password"
        v-model="confirmedPassword"
        required
      ></wvrz-input>
      <input id="confirm" type="submit" value="confirm" hidden />
      <p v-if="!same" class="error">
        Les mots de passe doivent être identiques
      </p>
      <p v-else-if="tooShort" class="error">
        Le mot de passe doit faire 8 caractères minimum
      </p>
      <wvrz-button
        v-else
        text="Valider"
        for="confirm"
        buttonType="submit"
      ></wvrz-button>
    </form>
  </div>
  </div>
</template>

<script>
import WvrzInput from '../../generics/WvrzInput.vue'
import WvrzButton from '../../generics/WvrzButton.vue'

export default {
  components: { WvrzInput, WvrzButton },
  name: 'CibiResetPassword',
  data () {
    return {
      password: '',
      confirmedPassword: '',
      token: undefined
    }
  },
  methods: {
    confirm () {
      this.$users.setPassword(this.token, this.password, (err, data) => {
        if (!err) {
          sessionStorage.clear()
          this.$utils.success(
            'Succès !',
            'Vous pouvez maintenant vous connecter'
          )
          this.$router.push('/')
        }
      })
    }
  },
  computed: {
    same: function () {
      return this.confirmedPassword === this.password
    },
    tooShort: function () {
      return this.password.length < 8
    }
  },
  mounted () {
    if (this.$route.params) {
      this.token = this.$route.params.token
      sessionStorage.setItem('user_token', this.token)
    } else {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style.scss";
.register-password{
  padding-top : 100px
}
.login {
  background-color: $login-background;
  color: $login-text-color;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  max-width: 500px;
  border-radius: 20px;
  font-family: $font-family;
  position: relative;
}

@media (max-width: 800px) {
  .login {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .login {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
  }
  .register-password {
  padding-top: 0px;
  height : 100%;
  overflow-y: auto;
  background : $login-background;
}
}
.error {
  color: red;
}
#login-logo {
  max-width: 60%;
  margin-top: 40px;
  margin-bottom: 40px;
  max-height: 100px;
}

form {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

input {
  margin-bottom: 20px;
  font-family: $font-family;
  width: 100%;
}

.confirm-button {
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: $button-color;
  border-radius: 10px;
}

label:hover {
  cursor: pointer;
}


h2 {
  color: $login-text-color;
  width: 100%;
  text-align: center;
}

p {
  margin: 0px 20px;
  text-align: center;
  font-size: small;
}
</style>
