<template>
  <div id="map">
    <mapbox-map 
      ref="map"
      auto-resize
      :center="[2,47.41322]"
      :zoom="zoom" 
      accessToken="pk.eyJ1Ijoid2VhdmVyaXplIiwiYSI6ImNsOHNxOGw4NDNnZTIzd281aDRzMHBmNGwifQ.Foztgsd2GeyswcrorktEQg" >
      <mapbox-geogeometry-polygon  fillColor="rgba(00, 00, cc, 0.4)"  :path="getPosition(room)" :color="getColor(room)"></mapbox-geogeometry-polygon>
      <mapbox-marker v-for="(user, index) in participants" :key="index" :lngLat="[getUserPosition(user.position).lng,getUserPosition(user.position).lat]">
        <template v-slot:icon>
          <img src="@/assets/personpin.svg">
        </template>
      </mapbox-marker>
    </mapbox-map>
  </div>
</template>
<script>
import { MapboxMap,MapboxGeogeometryPolygon,MapboxMarker } from "vue-mapbox-ts";
export default {
  name: 'LeafletMap',
  components: {
    MapboxMap,
    MapboxGeogeometryPolygon,
    MapboxMarker
  },
  data () {
    return {
      zoom: 4,
      icon:{
        iconUrl: require('@/assets/personpin.svg'),
        iconSize: [48 , 48],
        iconAnchor: [24, 48]
      },
    }
  },
  props: {
    room: Object,
    participants: Array,
    route : String,
    page : Number
  },
  watch: {
    page: function() {
    },
    route: function(to, from){
      if(to==="room"){
        setTimeout(()=>{window.dispatchEvent(new Event('resize'))},500);
      }
    }
  },
  mounted(){
        setTimeout(()=>{window.dispatchEvent(new Event('resize'))},1000);
  },
  methods: {
    getUserPosition(pos){
      if(pos){
        return pos
      }else{
        return { lat: 0, lng:0 }
      }
    },
    getPosition (room) {
      if (!room) {
        return []
      }
      var pos = room.position
      var ret = []
      if(pos && pos.length >0){
        pos.forEach((p) => {
          ret.push([ p.lng,p.lat ])
        })
        ret.push([  pos[0].lng,pos[0].lat ])
      }
      
      return ret
    },
    getColor (id) {
      return '#3388ff'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

.profil {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#map {
  width: 100%;
  height: 100%;
}
</style>
