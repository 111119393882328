import { createApp } from 'vue'
import './registerServiceWorker'
import { createRouter, createWebHistory } from 'vue-router'
import App from './app.vue'
import { _get, _post, _postFile, _put, _patch, _delete } from './services/http-utils.js'
import { _authenticate, _isLoggedIn, login, logout } from './services/auth-service.js'


import Main from './components/Main.vue'
import CibiLogin from './components/pages/login/CibiLogin.vue'
import CibiRegister from './components/pages/login/CibiRegister.vue'
import CibiResetPassword from './components/pages/login/CibiResetPassword.vue'
import CibiDemandPassword from './components/pages/login/CibiDemandPassword.vue'

import VTooltip from 'v-tooltip'

const http = require('./services/http-utils.js')
const utils = require('./services/utils.js')
const users = require('./services/user-service.js')
const chat = require('./services/chat-service.js')
const room = require('./services/room-service.js')
const geoloc = require('./services/geolocation-service.js')

const routes = [
  {
    path: '/',
    component: CibiLogin,
    beforeEnter: (to, from, next) => {
      if(sessionStorage.getItem("logout")) {
        sessionStorage.clear();
        next('/');
      } else {
        if (!sessionStorage.getItem("logout") && sessionStorage.getItem('user_token')) {
          users.me((err, ok) => {
            if (!err) {
              if (ok) {
                next('/main/room')
              }
            } else {
              next();
            }
          })
        } else {
          next()
        }
      }
    }
  },
  {
    path: '/main/:inside',
    component: Main,
    beforeEnter: (to, from, next) => {
      redirectToMain(next)
    }
  },
  {
    path: '/register',
    component: CibiRegister
  },
  {
    path: '/setpassword/:token',
    component: CibiResetPassword
  },
  {
    path: '/reset',
    component: CibiDemandPassword
  }
]

function redirectToMain (next) {
  if (!sessionStorage.getItem('user_token')) {
    next('/')
  } else {
    next()
  }
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const app = createApp(App)

app.config.globalProperties.$login = login
app.config.globalProperties.$logout = logout

app.config.globalProperties.$utils = utils
app.config.globalProperties.$http = http
app.config.globalProperties.$api = http.BASE_URL

app.config.globalProperties.$chat = chat
app.config.globalProperties.$room = room
app.config.globalProperties.$geoloc = geoloc;

// services definitions
app.config.globalProperties.$users = users

app.config.globalProperties.$get = _get
app.config.globalProperties.$post = _post
app.config.globalProperties.$postFile = _postFile
app.config.globalProperties.$patch = _patch
app.config.globalProperties.$put = _put
app.config.globalProperties.$delete = _delete
app.config.globalProperties.$authenticate = _authenticate
app.config.globalProperties.$isLoggedIn = _isLoggedIn

app.use(router).mount('#app')
app.use(VTooltip, {
  classes: 'bg-neutral-700 opacity-100 rounded-md text-white'
})
