var httpUtils = require('./http-utils.js')

function me (cb) {
  httpUtils._get('/users/me?filter[include]=roles', cb)
}

function findByName (name, cb) {
  httpUtils._get(`/users?filter[where][username][like]=${name}&filter[include]=wallet`, cb)
}

function setPassword (token, password, cb) {
  httpUtils._post('/users/create', { token, password }, cb)
}

function register (name, email, cb) {
  httpUtils._post('/users/register', { name, email }, cb)
}

function resetPassword (email, cb) {
  httpUtils._post('/users/reset', { email }, cb)
}

function connectUserToRoom(userId,roomId,cb) {
 /* if(roomId){
    httpUtils._get('/rooms/'+roomId+'/exists', (err, result) => {
      if(result && result.exists) {
        httpUtils._put('/rooms/'+roomId+'/users/rel/' + userId,{}, cb)
      } else {
        cb(null);
      }
    })
  }*/
  cb();
  
}

function changeUserConnectionStatusInRoom(userId,roomId,connectionStatus,cb){
 /* if(roomId){
    connectUserToRoom(userId,roomId,(err,relation)=>{
      if(relation){
        httpUtils._patch('/RoomUserRelations/'+relation.id,{connected : connectionStatus}, cb)
      }
      
    });
  }*/
  cb();
  
}

module.exports = { me, findByName, setPassword, register, resetPassword ,connectUserToRoom,changeUserConnectionStatusInRoom}
