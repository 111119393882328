<template>
<div class="list">
<div id="header">Participants : </div>
<div id="listContent">
  <div class="participant"  v-for="element in elements" :key="element.id">
    <span :class="{red : true,active : getStatusActive(element)}"></span>
    <span class="participantText"> {{ element.username }} </span>
          
    <span class="material-icons microphoneIcon">
      {{ getMicrophoneIcon(element) }}
    </span>
    
  </div>
  </div>
  </div>
</template>
<script>
export default {
  components: { },
  name: 'CibiParticipantsList',
  data () {
    return {}
  },
  props: {
    elements: Array
  },
  methods: {
    getMicrophoneIcon (user) {
      return (user.janus&& user.janus.audioenabled ? 'mic' : 'mic_off')
    },
    getStatusActive(user){
      if(!user.janus){
        return false
      }else{
        if(user.janus.webRtcStarted && user.janus.remoteStream){
          return true;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.active{
  background  : #2eaf05 !important;
}

.red{
  width : 20px;
  height : 20px;
  margin : 10px;
  border-radius : 20px;
  background  : red;
}
.list{
  background : $participant-background;
  padding : 20px;
  border-bottom : 2px solid $participant-border;
  box-sizing : border-box;
  padding-bottom : 0;
}
#listContent{
  overflow-y : auto;
    height: calc(100% - 50px);
    width: calc(100% + 30px);
}
#header{
  font-weight : bold;
  padding-bottom : 10px;
  color : $participant-color;
}
.participant{
  display : flex;
  align-items : center;
  color : $participant-color;
}
.participantIcon{
  font-size : 30pt;
  margin-right : 15px;
}
.participantText{
  font-size : 14pt;
  font-family : Roboto;
  margin-right : 10px;
  flex: 1;
}
.microphoneIcon{
  margin-right : 20px;
 font-size : 15pt;
}

</style>
