<template>
  <div id="tabs">
    <div
      class="tab"
      v-for="(tab, index) in tabs"
      v-bind:class="{ selected: isSelected(index) }"
      :key="index"
      @click="switchToTab(index)"
    >
      {{ tab }}
    </div>
    <div
      id="selector"
      v-bind:style="{
        width: getWidth(this.tabs),
        left: getLeft(this.selected),
      }"
      ref="selector"
    ></div>
  </div>
</template>

<script>
export default {
  name: "WvrzTabBar",
  props: {
    tabs: Array,
  },
  methods: {
    switchToTab(index) {
      console.log(this.tabs);
      this.selected = index;
      this.$emit("switch",index);
    },
    getWidth(tabs) {
      return 100 / this.tabs.length + "%";
    },
    getLeft(selected) {
      return (this.selected * 100) / this.tabs.length + "%";
    },
    isSelected(index) {
      return index === this.selected;
    },
    windowSizeChange(e) {
      var w = window.innerWidth;
      if (w>800){
        this.selected = 0;
      }else{
        this.selected = 1;
      }
      this.$emit("switch",this.selected);
    }
  },
  data() {
    return {
      selected: 1,
    };
  },


  created() {
    window.addEventListener("resize", this.windowSizeChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChange);
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
#tabs {
  height: 50px;
    flex-shrink: 0;
  width: 100%;
  background: $tab-background;
  display: flex;
  align-items: center;
  position: relative;
  cursor : pointer;
}
.tab {
  flex: 1;
  text-align: center;
  color: $tab-color;
}
#selector {
  position: absolute;
  bottom: 0;
  background: $tab-selected-color;
  height: 3px;
  transition: all 0.5s;
}
.selected {
  color: $tab-selected-color;
}
.selected {
}
</style>
