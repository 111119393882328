<template>
  <div class="channel" @click="start">
    <audio
      ref="audio"
      class="rounded centered"
      id="roomaudio"
      width="100%"
      :srcObject.prop="stream"
      height="100%"
      controls
      autoplay
    />
  </div>
</template>

<script>
import { Janus } from "janus-gateway";
export default {
  name: "CibiJanusConnection",
  data() {
    return {
      janus: null,
      plugin: null,
      stream: null,
      error: null,
      status: null,
      initialized: false,
      initializedRoom: false,
      streamList: {
        selected: null,
        options: [],
      },
      webrtcUp: false,
      mediaDevices: [],
      participants: [],
    };
  },
  props: {
    janusStatus: Object,
    roomId: String,
    user: Object,
    audioenabled: Boolean,
  },
  watch: {
    audioenabled: function (val) {
      this.plugin.send({
        message: { request: "configure", muted: !val },
      });
      this.janusStatus.audioenabled = val;
      this.$emit("janusStatusUpdate", this.janusStatus);
    },
    roomId: function (val) {
      console.log("Janus : changed room id", val);
      if (this.roomId && this.initialized) {
        this.register();
      }
    },
    initialized: function () {
      console.log("Janus : initialized");
      if (this.roomId && this.initialized) {
        this.register();
      }
    },
  },
  methods: {
    connect() {
      if (!navigator.mediaDevices) {
        return;
        console.error("Janus : No audio device found");
      }
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.mediaDevices = devices;
      });
      var server = "https://janus.weaverize.com:8089/janus";
      this.janus = new Janus({
        server,
        success: () => {
          this.janusStatus.userId = this.user.id;
          this.janusStatus.started = true;
          this.janusStatus.audioenabled = this.audioenabled;
          this.janusStatus.roomId = this.roomId ;
          this.$emit("janusStatusUpdate", this.janusStatus);
          this.attachPlugin();
        },
        error: (error) => {
          this.janusStatus.started = false;
          this.$emit("janusStatusUpdate", this.janusStatus);
          this.onError("Failed to connect to janus server", error);
        },
        destroyed: () => {
          window.location.reload();
        },
      });
    },
    attachPlugin() {
      var opaqueId = "videoroomtest-" + Janus.randomString(12);
      this.janus.attach({
        plugin: "janus.plugin.audiobridge",
        opaqueId: opaqueId,
        success: (plugin) => {
          this.janusStatus.attached = true;
          
          this.$emit("janusStatusUpdate", this.janusStatus);
          this.plugin = plugin;

          this.initialized = true;
        },
        consentDialog: (on) => {
          console.log(
            "Consent dialog should be " + (on ? "on" : "off") + " now"
          );
        },
        error: (error) => {
          this.janusStatus.attached = false;
          this.$emit("janusStatusUpdate", this.janusStatus);
          this.onError("Error attaching plugin... ", error);
        },
        iceState: (state) => {
          this.janusStatus.iceState = state;
          this.$emit("janusStatusUpdate", this.janusStatus);
          console.log("ICE state changed to " + state);
        },
        mediaState: (medium, on, mid) => {
        },
        webrtcState: (on) => {
        },
        onmessage: (msg, jsep) => {
          if (msg.audiobridge) {
            if (msg.audiobridge === "joined") {
              this.janusStatus.lastMessage = "joined";
              this.janusStatus.janusId = (msg.participants.length>0?msg.participants[0].id:null);
              this.$emit("janusStatusUpdate", this.janusStatus);
              this.startWebRtc();
            }
            if (msg.audiobridge === "roomchanged") {
              this.janusStatus.lastMessage = "roomchanged";

              this.$emit("janusStatusUpdate", this.janusStatus);
            }
            if (msg.audiobridge === "destroyed") {
              this.janusStatus.lastMessage = "destroyed";

              this.$emit("janusStatusUpdate", this.janusStatus);
            }
            if (msg.participants) {
             /* msg.participants.forEach((participant) => {
                var found = null;
                this.participants.forEach((localParticipant, index) => {
                  if (localParticipant.id === participant.id) {
                    found = index;
                  }
                });
                if (found !== null) {
                  Object.keys(participant).forEach((key) => {
                    this.participants[found][key] = participant[key];
                  });
                } else {
                  this.participants.push(participant);
                }
              });*/
            }
            if (msg.audiobridge === "event") {
              if (msg.participants) {
                msg.participants.forEach((participant) => {
                  var found = null;
                  this.participants.forEach((localParticipant, index) => {
                    if (localParticipant.id === participant.id) {
                      found = index;
                    }
                  });
                  if (found !== null) {
                    Object.keys(participant).forEach((key) => {
                      this.participants[found][key] = participant[key];
                    });
                  } else {
                    this.participants.push(participant);
                  }
                });
                this.$emit("participantsChanged", this.participants);
              }

              if (msg.error) {
                this.janusStatus.error = "error";

              this.$emit("janusStatusUpdate", this.janusStatus);
                console.error("Janus : error", msg.error);
              }
              if (msg.leaving) {
                this.janusStatus.lastMessage = "leaving";

              this.$emit("janusStatusUpdate", this.janusStatus);
                console.log("Janus : leaving");
                var found = null;
                this.participants.forEach((localParticipant, index) => {
                  if (localParticipant.id === msg.leaving) {
                    found = index;
                  }
                });
                if (found !== null) {
                  this.participants.splice(found, 1);
                }
                this.$emit("participantsChanged", this.participants);
              }
            }
          }
          if (jsep) {
            this.plugin.handleRemoteJsep({ jsep: jsep });
          }
        },
        onlocaltrack: (stream, on) => {
          this.janusStatus.localStream = true;

              this.$emit("janusStatusUpdate", this.janusStatus);
          this.onLocalStream(stream, on);
        },
        onremotetrack: (track, mid, on) => {
          this.janusStatus.remoteStream = true;

              this.$emit("janusStatusUpdate", this.janusStatus);
          this.onRemoteStream(track, mid, on);
        },
        oncleanup: () => {
          this.onCleanup();
        },
      });
    },
    getIntFromString(str) {
      var hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash < 0 ? hash * -1 : hash;
    },

    register() {
      this.participants = [
        { display: this.user.username, id: this.user.id, me: true },
      ];
      this.$emit("participantsChanged", this.participants);
      var register = {
        request: "join",
        room: this.getIntFromString(this.roomId),
        display: this.user.username,
        codec: "opus",
      };
      var changeRoom = {
        request: "changeroom",
        room: this.getIntFromString(this.roomId),
        display: this.user.username,
        codec: "opus",
      };
      var createRoom = {
        request: "create",
        room: this.getIntFromString(this.roomId),
        is_private: false,
      };

      this.plugin.send({ message: createRoom });
      console.log("Changing room");

      if (!this.initializedRoom) {
        this.plugin.send({ message: register });
        this.initializedRoom = true;
      } else {
        try {
          this.plugin.send({ message: changeRoom });
          console.log("could not change room");
        } catch (error) {
          this.plugin.send({ message: register });
        }
      }

      this.plugin.send({
        message: { request: "configure", muted: !this.audioenabled },
      });
    },
    startWebRtc() {
      if (!this.webrtcUp) {
        this.webrtcUp = true;
        // Publish our stream
        this.plugin.createOffer({
          media: { video: false },
          customizeSdp: (jsep) => {
            /* if (stereo && jsep.sdp.indexOf("stereo=1") == -1) {
              jsep.sdp = jsep.sdp.replace(
                "useinbandfec=1",
                "useinbandfec=1;stereo=1"
              );
            } */
          },
          success: (jsep) => {
            this.janusStatus.webRtcStarted = true;

              this.$emit("janusStatusUpdate", this.janusStatus);
            console.log("Janus : Got SDP!", jsep);
            var publish = { request: "configure", muted: false };
            this.plugin.send({ message: publish, jsep: jsep });
          },
          error: (error) => {
            this.janusStatus.webRtcStarted = false;
            this.janusStatus.webRtcError = error;

              this.$emit("janusStatusUpdate", this.janusStatus);
            console.error("Janus : WebRTC error:", error);
          },
        });
      }
    },
    start() {
      this.plugin.send({
        message: { request: "watch", id: this.streamList.selected },
      });
      this.startWebRtc();
    },
    stop() {
      this.plugin.send({ message: { request: "stop" } });
      this.plugin.hangup();
      this.onCleanup();
    },
    onRemoteStream(track, mid, on) {
      if (this.stream || track.kind !== "audio") return;
      if (!on) {
        // Track removed, get rid of the stream and the rendering
        if (this.stream) {
          try {
            var tracks = remoteStream.getTracks();
            for (var i in tracks) {
              var mst = tracks[i];
              if (mst) mst.stop();
            }
          } catch (e) {}
        }
        this.stream = null;
        return;
      }
      this.stream = new MediaStream();
      this.stream.addTrack(track.clone());

      //Janus.attachMediaStream(this.$refs.audio, remoteStream);
    },
    onLocalStream(stream, on) {
      console.log("Janus : got local stream", stream);
      /* if (stream && stream.getTracks) {
        stream.getTracks().forEach((track) => {
          track.applyConstraints({
            echoCancellation: true,
            noiseSuppression: true,
          });
        });
      }
      if (stream && stream.applyConstraints) {
        stream.applyConstraints({
          echoCancellation: true,
          noiseSuppression: true,
        });
      }*/
    },
    onCleanup() {
      this.stream = null;
      this.status = null;
      this.error = null;
    },
    onError(message, error = "") {
      Janus.error(message, error);
      this.error = message + error;
    },
  },
  mounted() {
    const dependencies = Janus.useDefaultDependencies();
    dependencies.webRTCAdapter = dependencies.webRTCAdapter.default;

    Janus.init({
      debug: "all",
      dependencies: dependencies,
      callback: () => {
        this.connect();
      },
    });
  },
  unmounted() {
    var leave = {
      request: "leave",
    };
    if (this.plugin) {
      console.log("Janus : leaving room");
      this.plugin.send({ message: leave });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
#roomaudio {
  display: none;
}
</style>
