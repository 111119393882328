<template>
  <div class="messagesList" ref="chatMessages">
    <div class="message" v-for="element in messages" :key="element.id">
      <div class="messageTime">
        {{ element.time }}
      </div>
      <span class="material-icons participantIcon" v-if="element.username !== 'System'">account_circle</span>
      <div class="messageText">
        <div class="author" v-if="element.username !== 'System'">
          {{ element.username }}
        </div>
        <div class="messageContent" v-bind:class="{ system: element.username === 'System' }">
          {{ element.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CibiChatroom",
  data() {
    return {
      messages: [],
      user: null
    };
  },
  props: {
    roomId: String,
  },
  watch: {
    roomId: function (val) {
      this.$users.me((err, data) => {
        if (!err) {
          this.user = data.user
          if (this.roomId) {
            this.messages = [];
            this.$chat.connectToChat(this.roomId, this.user.id, msg => {
              var parsedMessage = JSON.parse(msg);
              var date = new Date(parsedMessage.value.time);
              if (date.getHours()) {
                if (date.getMinutes().toString().length === 1)
                  parsedMessage.value.time = date.getHours() + ':0' + date.getMinutes()
                else
                  parsedMessage.value.time = date.getHours() + ':' + date.getMinutes()
              } else
                parsedMessage.value.time = '0:00'

              if (parsedMessage.type === "chat") {
                this.messages.push(parsedMessage.value);
              } else {
                if (parsedMessage.type === "participants") {
                  this.$emit("participantsChanged", parsedMessage.value);
                }
              }
              this.$nextTick(function () {
                var container = this.$refs.chatMessages;
                container.scrollTop = container.scrollHeight + 120;
              });
            });
          }
        }
      })

    },
  },
  unmounted() {
    this.$chat.disconnectChat();
  },
  methods: {
    disconnect() {
      this.$chat.disconnectChat();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

.messagesList {
  padding: 20px;
  width: 100%;
  color: $chat-text-color;
  width: calc(100% + 15px);
  overflow-y: scroll;
}

.message {
  width: 100%;
  display: flex;
  align-items: center;
  color: $chat-text-color;
  font-family: $font-family;
  margin-bottom: 10px;
}

.author {
  font-weight: bold;
}

.messageText {
  width: calc(100% - 50px);
  overflow-wrap: break-word;
}

.messageContent {
  flex: 1;
}

.system {
  font-style: italic;
  margin-left: 0px;
}

.participantIcon {
  align-self: baseline;
  font-size: 30pt;
  margin-right: 15px;
}

.messageTime {
  font-size: 10pt;
  margin-right: 15px;
  width: calc(8%);
}
</style>
