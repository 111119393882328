var httpUtils = require('./http-utils.js')

function login (credentials, cb) {
  httpUtils._post('/users/login', credentials, (err, data) => {
    if (err) {
      cb(err, null)
    } else {
      if (data && data.id) {
        sessionStorage.setItem('user_token', data.id)
        cb(null, data)
      } else {
        cb(new Error(`No access token for user ${credentials.email}`))
      }
    }
  })
}

function logout (cb) {
  httpUtils._post('/users/logout', null, cb)
}

module.exports = { login, logout }
